import React, {Component} from 'react';
import {Container, StyledBody, StyledHeader, StyledModal, SurveyDropDown, FieldWrapper, FieldTitle, SurveyType, StartDateContainerV2, SurveyDepartments, SurveyLocations, SurveyMessage, TextArea, LaunchButton, ButtonContainer, InviteContainerV2, ScrollableContainerV2, LocationContainer} from "./styles";
import PropTypes from "prop-types";
import {DropdownItem} from "react-bootstrap";
// import {challengePointItems} from "../../../../mockData.json";
import {CheckMark, CustomCheckbox, DateIconContainer, InputContainer, InviteAmigo, NameContainer, SaveButton, StyledDateTime} from "../../CreateEvent/styles";
import moment from "moment";
import {convertDateInTimezone, getPermissionStatus} from "../../../utils/methods";
import {getCompanyDepartment, getCompanyLocation, launchSurveyByType, getSurveyLibraries, getSurveyDetails, launchSurvey} from "../../../redux/actions";
import {connect} from "react-redux";
import {isNull, isUndefined } from "lodash";
import Waiting from "../../Waiting";
import RadioButton from "../../CustomRadioButton";
import {RadioButtonSurveyConatiner} from "../../CreateSurveys/styles"
import LazyImage from '../../common/LazyImage/LazyImage';
import { ImageUrl } from '../../../utils/constants';
class LaunchSurveyPopUp extends Component{
  constructor(props) {
    super(props);
    this.state = {
      companyId: props.companyInfo['id'],
      surveyType: '',
      openDateTimePicker:'',
      showDateTimeInput: '',
      date: moment().add(1, 'days').format('MM/DD/YYYY'),
      endDate: moment().add(1,'days').format('MM/DD/YYYY'),
      surveyDepartment: [],
      surveyLocation: [],
      message: '',
      surveyId: '',
      surveyFor: 0,
      sections: [],
      questions: [],
      title: '',
      imgSrc: '',
      imageName: '',
      imageUpdated: 0,
      surveyPoints: 'Tier 1: 25 points',
      hasSections: 0
    }
  }

  challengePointItems= [
    "Tier 1: 25 points",
    "Tier 2: 100 points",
    "Tier 3: 250 points",
    "Tier 4: 500 points"
  ]

  componentDidMount() {
    const{getCompanyLocation, getCompanyDepartment, getSurveyLibraries, surveyLibraries } = this.props;
    getCompanyLocation(this.state.companyId,false);
    getCompanyDepartment(this.state.companyId,false);
    document.addEventListener('mousedown', this.handleClick);
    if(surveyLibraries.length === 0)getSurveyLibraries();
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }
  onSelect = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  onSelectSurveyType = (name, value, id) => {
    this.setState({
      [name]: value,
      surveyId: id
    }, () => this.props.getSurveyDetails(id, this.props.history, '', true));
  };
  toggleSurveyForRadioButton = (e) => {
    this.setState({
      surveyFor: e.target.value
    });
  };

  changeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if(showDateTimeInput === 'date') {
      this.setState({
        date: moment(e._d).format('MM/DD/YYYY'),
        endDate: moment(e._d).format('MM/DD/YYYY'),
        openDateTimePicker: '',
      });
    } else if(stateName.includes('ate')) {
      this.setState({
        openDateTimePicker: '',
        [stateName]: moment(e._d).format('MM/DD/YYYY'),
      });
    }
  };
  handleClick = (e) => {
    if(this.datePickerWrapper && !this.datePickerWrapper.contains(e.target)) {
      this.setState({
        openDateTimePicker: ''
      });
    }
  };
  showDatePicker = (value) => {
    if(this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: '',
        date: moment().add(1, 'days').format('MM/DD/YYYY'),
        endDate: moment().add(1,'days').format('MM/DD/YYYY'),
      });
    } else {
      this.setState({
        openDateTimePicker: value
      });
    }
  };
  setDatePickerWrapper = (node) =>  {
    this.datePickerWrapper = node;
  };
  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChangeLocation = (id, name) => {
    const { surveyLocation, surveyDepartment} = this.state;
    if(name == 'surveyLocation') {
      if (surveyLocation.includes(id)) {
        let index = surveyLocation.findIndex((item) => item === id);
        if (index > -1) {
          surveyLocation.splice(index, 1);
        }
      } else {
        surveyLocation.push(id);
      }
      this.setState({
        surveyLocation: surveyLocation
      })
    } else{
      if (surveyDepartment.includes(id)) {
        let index = surveyDepartment.findIndex((item) => item === id);
        if (index > -1) {
          surveyDepartment.splice(index, 1);
        }
      } else {
        surveyDepartment.push(id);
      }
      this.setState({
        surveyDepartment: surveyDepartment
      })
    }
  };
  selectAll = (e, details, name) => {
    e.preventDefault();
    const { surveyLocation, surveyDepartment} = this.state;
    if(name == 'surveyLocation') {
      if(surveyLocation.length === details.length) {
        this.setState({
          surveyLocation: []
        })
      }
      else {
        let arr = [];
        for(let i=0; i< details.length; i++) {
          arr.push(details[i].id);
        }
        this.setState({
          surveyLocation: arr
        })
      }
    } else {
      if(surveyDepartment.length === details.length) {
        this.setState({
          surveyDepartment: []
        })
      }
      else {
        let arr = [];
        for(let i=0; i< details.length; i++) {
          arr.push(details[i].id);
        }
        this.setState({
          surveyDepartment: arr
        })
      }
    }
  };
  onSubmit = () => {
    const{history, launchSurvey, departmentDetails, locationDetails } = this.props;
    const {title, imgSrc, surveyPoints, message, hasSections, date, endDate, surveyFor, surveyLocation, surveyDepartment, sections, questions, companyId, imageUpdated, surveyId} = this.state;
    let obj = {
      'title': title,
      'image': imgSrc,
      'company_id': companyId,
      'start_date': moment(date).format('YYYY-MM-DD'),
      'end_date': moment(endDate).format('YYYY-MM-DD'),
      'city_states': [],
      'departments': [],
      'description': message,
      'survey_point': surveyPoints === "Tier 1: 25 points" ? 25 : surveyPoints === "Tier 2: 100 points" ? 100 : surveyPoints === "Tier 3: 250 points" ? 250 : 500,
      'has_sections': hasSections,
      'imageUpdated': imageUpdated,
      'launch_survey_for': surveyFor,
      'data': hasSections !== 0 ? sections : questions,
      'survey_id': surveyId
    };
    if ((departmentDetails && departmentDetails.length) || (locationDetails && locationDetails.length)) {
      // (surveyFor === 1) ? obj['departments'] = surveyDepartment : obj['city_states'] = surveyLocation;
      if(surveyFor === 1){
        obj['departments'] = surveyDepartment
      }
      else{
        obj['city_states'] = surveyLocation
      }
    }
    launchSurvey(obj, history, true);
    this.props.onHide();
  };
  componentDidUpdate(prevProps) {
    const { departmentDetails, locationDetails, surveyDetails} = this.props;
    if(prevProps.departmentDetails != departmentDetails || prevProps.locationDetails != locationDetails) {
      let departmentStatus = departmentDetails && departmentDetails.length;
      let locationStatus = locationDetails && locationDetails.length;
      if (!(departmentStatus && locationStatus) && (departmentStatus || locationStatus)) {

        this.setState({
          surveyFor: (!departmentStatus) ? 0 : 1
        })
      }
    }
    if(prevProps.surveyDetails !== surveyDetails){
      this.setState({
        surveyId: surveyDetails.id,
        imgSrc: surveyDetails.image,
        title: surveyDetails.survey_name,
        message: '',
        imageName: surveyDetails.image,
        hasSections: surveyDetails.has_sections,
        surveyPoints: surveyDetails.survey_point === 25 ? "Tier 1: 25 points" :
          surveyDetails.survey_point === 100 ? "Tier 2: 100 points" :
            surveyDetails.survey_point === 250 ? "Tier 3: 250 points" :
              surveyDetails.survey_point === 500 ? "Tier 4: 500 points" : "Tier 1: 25 points",
        sections: surveyDetails.has_sections === 1 ? surveyDetails.data : [],
        questions: surveyDetails.has_sections !== 1 ? surveyDetails.data : []
      })
    }
  }
  render() {
    const { show, locationDetails, departmentDetails, surveyLibraries, surveyDetails, userPermissions} = this.props;
    const {showDateTimeInput, openDateTimePicker, date, endDate, surveyType, surveyPoints, surveyDepartment, surveyLocation, message, surveyFor} = this.state;
    const valid = (currentDate) => showDateTimeInput === 'date' ? currentDate.isAfter(moment()) : currentDate.isAfter(convertDateInTimezone(date).subtract(1, 'days'));
    if(isNull(locationDetails) || isUndefined(locationDetails) || isNull(departmentDetails) || isUndefined(departmentDetails) || !surveyLibraries || !surveyDetails){
      return <Waiting />;
    }
    let isDisabled = false;
    const locationStatus = locationDetails && locationDetails.length;
    const departmentStatus = departmentDetails && departmentDetails.length;
    if( !surveyPoints || !surveyType || message == ''){
      isDisabled = true;
    }
    const showLocations = getPermissionStatus("Show locations in survey", userPermissions);
    const showDeparments = getPermissionStatus("Show departments in survey", userPermissions);
    if ((showLocations && locationStatus) || (showDeparments && departmentStatus)) {
      if (surveyFor === 1 && !surveyDepartment.length){
        isDisabled = true;
      } else if(surveyFor !==1 && !surveyLocation.length) {
        isDisabled = true;
      }
    }
    if(endDate < date){
      isDisabled = true;
    }
    return (
      <StyledModal show={show} onHide={() => this.props.onHide()}>
        <StyledHeader closeButton>
          <Container>
            <div className="headerName">
              Launch Survey
            </div>
            <div className="subTitle">
              Send an Invitation to your Employees
            </div>
          </Container>
        </StyledHeader>
        <StyledBody>
          <FieldWrapper>
            <SurveyType>
              <FieldTitle>Survey Type:</FieldTitle>
              <SurveyDropDown inputpadding="9px"
                type="button" title={<div className="title">{surveyLibraries.length == 0 ? 'No libraries' : surveyType == '' ? 'Select a library' : surveyType}</div>}
                id={"surveyType"}
              >{
                  surveyLibraries.map((list) => (
                    <DropdownItem key={list.id} isActive={surveyType == list.survey_name} onSelect={() => this.onSelectSurveyType('surveyType', list.survey_name, list.id)}>{list.survey_name}</DropdownItem>
                  ))
                }
              </SurveyDropDown>
            </SurveyType>
            <SurveyType>
              <FieldTitle>Survey Point Value:</FieldTitle>
              <SurveyDropDown inputpadding="9px"
                type="button" title={<div className="title">{surveyPoints}</div>}
                id={"surveyPoints"}
              >{
                  this.challengePointItems.map((list, index) => (
                    <DropdownItem key={index} isActive={surveyPoints == list} onSelect={() => this.onSelect('surveyPoints',list)}>{list}</DropdownItem>
                  ))
                }
              </SurveyDropDown>
            </SurveyType>

            {<SurveyType>
              <FieldTitle>Time to complete:</FieldTitle>
              <div>
                <StartDateContainerV2>
                  <div>From</div>
                  <InputContainer>
                    <div ref={(node) => openDateTimePicker === 'date' && this.setDatePickerWrapper(node)}>
                      <StyledDateTime
                        open={openDateTimePicker === 'date'}
                        inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true}}
                        dateFormat="MM/DD/YYYY"
                        closeOnSelect={true}
                        closeOnTab={true}
                        timeFormat={false}
                        value={date}
                        onChange={(e) => this.changeDate(e, 'date')}
                        isValidDate={valid}
                      />
                      <DateIconContainer onClick={() => this.showDatePicker('date')}>
                        <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                      </DateIconContainer>
                    </div>
                  </InputContainer>
                </StartDateContainerV2>
                <StartDateContainerV2>
                  <div>To</div>
                  <InputContainer>
                    <div ref={(node) => openDateTimePicker === 'endDate' && this.setDatePickerWrapper(node)}>
                      <StyledDateTime
                        open={openDateTimePicker === 'endDate'}
                        inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true}}
                        dateFormat="MM/DD/YYYY"
                        closeOnSelect={true}
                        closeOnTab={true}
                        timeFormat={false}
                        value={endDate}
                        onChange={(e) => this.changeDate(e, 'endDate')}
                        isValidDate={valid}
                      />
                      <DateIconContainer onClick={() => this.showDatePicker('endDate')} >
                        <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                      </DateIconContainer>
                    </div>
                  </InputContainer>
                </StartDateContainerV2>
              </div>
            </SurveyType>
            }
            { (departmentDetails && departmentDetails.length > 0) && (locationDetails && locationDetails.length > 0) && (showLocations && showDeparments)?
              <SurveyType>
                <FieldTitle>Launch Survey For:</FieldTitle>
                <RadioButtonSurveyConatiner>
                  <RadioButton id="1" handler={this.toggleSurveyForRadioButton} value={1} isChecked={surveyFor === 1} label={'Departments'} challengeLeaderBoard={true}/>
                  <RadioButton id="2" handler={this.toggleSurveyForRadioButton} value={0} isChecked={surveyFor === 0} label={'Locations'} challengeLeaderBoard={true}/>
                </RadioButtonSurveyConatiner>
              </SurveyType>
              : null
            }
            {surveyFor == 1 ?
              showDeparments && departmentDetails && departmentDetails.length > 0 ?
                <SurveyDepartments>
                  <FieldTitle>Company Departments:</FieldTitle>
                  <ButtonContainer>
                    {departmentDetails && departmentDetails.length > 0 ?
                      <SaveButton color="#159fc9" onClick={(e) => this.selectAll(e, departmentDetails, 'surveyDepartment')}>
                        <i className="fas fa-check"/>
                        {
                          surveyDepartment.length === departmentDetails.length ? 'deselect all departments' : 'select all departments'
                        }
                      </SaveButton> : null
                    }
                  </ButtonContainer>
                  <InviteContainerV2>
                    <ScrollableContainerV2>
                      <LocationContainer>
                        {
                          departmentDetails && departmentDetails.length > 0 ? departmentDetails.map((list, index) => (

                            <InviteAmigo key={index}>
                              <CustomCheckbox>
                                <input
                                  type="checkbox"
                                  checked={surveyDepartment.includes(list.id)}
                                  onChange={() => this.onChangeLocation(list.id, 'surveyDepartment')}
                                />
                                <CheckMark checked={surveyDepartment.includes(list.id)} />
                              </CustomCheckbox>
                              <NameContainer onClick={() => this.onChangeLocation(list.id, 'surveyDepartment')}>
                                <span>{list.department}</span>
                              </NameContainer>
                            </InviteAmigo>
                          )) : null
                        }
                      </LocationContainer>
                    </ScrollableContainerV2>
                  </InviteContainerV2>
                </SurveyDepartments>
                : null
              :
              showLocations && locationDetails && locationDetails.length > 0 ?
                <SurveyLocations>
                  <FieldTitle>Company Locations:</FieldTitle>
                  <ButtonContainer>
                    {locationDetails && locationDetails.length > 0 ?
                      <SaveButton color="#159fc9" onClick={(e) => this.selectAll(e, locationDetails, 'surveyLocation')}>
                        <i className="fas fa-check"/>
                        {
                          surveyLocation.length === locationDetails.length ? 'deselect all locations' : 'select all locations'
                        }
                      </SaveButton> : null
                    }
                  </ButtonContainer>
                  <InviteContainerV2>
                    <ScrollableContainerV2>
                      <LocationContainer>
                        {
                          locationDetails && locationDetails.length > 0 ? locationDetails.map((loc, index) => (
                            <InviteAmigo key={index}>
                              <CustomCheckbox>
                                <input
                                  type="checkbox"
                                  checked={surveyLocation.includes(loc.id)}
                                  onChange={() => this.onChangeLocation(loc.id, 'surveyLocation')}
                                />
                                <CheckMark checked={surveyLocation.includes(loc.id)} />
                              </CustomCheckbox>
                              <NameContainer onClick={() => this.onChangeLocation(loc.id, 'surveyLocation')}>
                                <span>{loc.location}</span>
                              </NameContainer>
                            </InviteAmigo>
                          )) : null
                        }
                      </LocationContainer>
                    </ScrollableContainerV2>
                  </InviteContainerV2>
                </SurveyLocations>
                : null
            }
            <SurveyMessage>
              <FieldTitle>Message:</FieldTitle>
              <TextArea
                placeholder="Write your message"
                rows="8"
                name="message"
                value={message}
                onChange={(e) => this.onChangeInput(e)}
                data-gramm_editor="false"
                maxLength="100"
              />
            </SurveyMessage>
            <ButtonContainer>
              <LaunchButton onClick={this.onSubmit} disabled={isDisabled}>send invitation</LaunchButton>
            </ButtonContainer>
          </FieldWrapper>
        </StyledBody>
      </StyledModal>
    );
  }
}

LaunchSurveyPopUp.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  getCompanyLocation: PropTypes.func,
  getCompanyDepartment: PropTypes.func,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  companyInfo: PropTypes.object,
  launchSurveyByType: PropTypes.func,
  getSurveyLibraries: PropTypes.func,
  getSurveyDetails: PropTypes.func,
  isLoading: PropTypes.bool,
  surveyLibraries: PropTypes.array,
  history: PropTypes.object,
  surveyDetails: PropTypes.object,
  launchSurvey: PropTypes.func,
  userPermissions: PropTypes.array
};
const mapStateToProps = (state) => ({
  locationDetails: state.register.locationDetails,
  departmentDetails: state.register.departmentDetails,
  surveyLibraries: state.surveys.surveyLibraries,
  isLoading: state.surveys.isLoading,
  surveyDetails: state.surveys.surveyDetails,
  userPermissions: state.profileData.userPermissions
});
const mapDispatchToProps = (dispatch) => ({
  getCompanyLocation: (id, bool) => dispatch(getCompanyLocation(id, bool)),
  getCompanyDepartment: (id, bool) => dispatch(getCompanyDepartment(id, bool)),
  launchSurveyByType: (surveyDetails) => dispatch(launchSurveyByType(surveyDetails)),
  getSurveyLibraries: () => dispatch(getSurveyLibraries()),
  getSurveyDetails: (surveyId, history, route, pushStatus) => dispatch(getSurveyDetails(surveyId, history, route, pushStatus)),
  launchSurvey: (data, history, pushStatus) => dispatch(launchSurvey(data, history, pushStatus))
});
export default connect(mapStateToProps, mapDispatchToProps)(LaunchSurveyPopUp);