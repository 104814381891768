import styled, {css} from 'styled-components';
import {Modal} from "react-bootstrap";
const BoldFont = 'Rubik-Medium';
const MediunFont = 'Rubik-Regular';
import {CustomDropDown} from '../../PeopleHomeV2/styles';
import {StartDateContainer, CustomTextArea, InviteContainer, ScrollableContainer} from '../../CreateEvent/styles'
const StyledModal = styled(Modal)`
   padding: 0px;
  .modal-dialog {
   background-color: #F6F6F8;
   padding: 0px;
   border-radius: 6px;
    .modal-content {
      background-color: #F6F6F8;
    }
    .modal-header {
      border-bottom: 0px;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  .close {
    float: right;
    cursor: pointer;
    margin-top: -14px;
    outline: none;
  }
`;


const StyledBody = styled(Modal.Body)`
    padding: 30px 38px 30px 40px;
    margin: 0 auto;
`;

const Container = styled.div`
  width: 100%;  
  position: relative;
  padding: 10px 0 10px 0;
  .headerName{
    font-size: 28px;
    text-align: center;
    color: #67676A;
    text-align: center;
    margin-bottom: 5px;
    font-family: ${BoldFont}
  }
  .subTitle{
    font-size: 14px;
    letter-spacing: 0px;
    color: rgba(103,103,106,0.5);
    font-family: ${MediunFont};
    text-align: center;
  }
  @media (max-width: 720px) {
    width: 100%;
    margin: 0px;
  }
`;
const FieldWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  @media (max-width: 550px){
   padding: 10px;
  }
  .dropdown {
     width: 100%;
  }
 .dropdown-menu {
    min-width: 55%;
    text-align: left;
    > li {
        font-family: ${MediunFont};
        > a {
          padding: 5px 15px;
        }
      }
    > ul {
      max-width: 300px;
    }
    @media (max-width: 500px){
      width: 100%;
    }
 }
 .dropdown-toggle{
  background-color: #fff;
 }
 .open>.dropdown-toggle.btn-default{
  background-color: #fff;
  box-shadow: none;
 }
 .btn-group>.btn:focus, .btn-group>.btn:hover{
  z-index: 0;
 }
`;
const FieldTitle = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 24px;
  color: #282C37;;
  font-family: ${MediunFont};
  float: left;
  width: 100%;
  margin: 10px 0;
 `;
const SurveyDropDown = styled(CustomDropDown)`
  width: 55%;
  height: 48px;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  .title{
    font-size: 14px;
    font-family: ${MediunFont}
    color: #67676A;
    margin-left: ${({marginleft}) => marginleft ? '40px' : '0px'};
  }
  &:active, &:hover, &:focus, &:active:focus, ,&:active:hover {
    outline: none;
    background-color: #fff;
    color: rgb(51,51,51);
  }
  @media (max-width: 500px){
    width: 100%;
  }
`;
const SurveyType = styled.div`
  width: 80%;
  margin: 0 auto 15px;
  @media (max-width: 500px){
   width: 100%;
  }
`;
const StartDateContainerV2 = styled(StartDateContainer)`
 float: none;
 padding-right: 25px;
`;
const SurveyDepartments = styled.div`
 width: 80%;
  margin: 0 auto 15px;
 @media (max-width: 500px){
   width: 100%;
  }
`;
const SurveyLocations = styled.div`
 width: 80%;
 margin: 0 auto 15px;
 @media (max-width: 500px){
   width: 100%;
  }
`;
const SurveyMessage = styled.div`
  width: 80%;
  margin: 0 auto 15px;
  @media (max-width: 500px){
   width: 100%;
  }
`;
const TextArea = styled(CustomTextArea)`
 float: none;
 width: 100%;
 background: #fff;
 box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
 border: none;
 margin-top: 5px;
`;
const ButtonContainer = styled.div`
 width: 100%;
 text-align: center;
`;
const LaunchButton = styled.button`
 width: 230px;
 height: 50px;
 background: linear-gradient(0deg, #1E76AB, #1E76AB);
 border-radius: 25px;
 border: none;
 color: #fff;
 font-size: 15px;
 font-family: ${MediunFont};
 text-transform: uppercase;
 outline: none;
   ${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
  @media (max-width: 500px){
   width: 140px;
  }
`;
const InviteContainerV2 = styled(InviteContainer)`
  padding: 5px 5px 0px 15px;
  background-color: #fff;
  margin-top: 10px;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.08);
`;
const ScrollableContainerV2 = styled(ScrollableContainer)`
  height: 180px;
  padding: 0px;
`;
const LocationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 170px;
  align-items: center;
`;
export {StyledModal, StyledBody, StyledHeader, Container, SurveyDropDown, FieldWrapper, FieldTitle, SurveyType, StartDateContainerV2, SurveyDepartments, SurveyLocations, SurveyMessage, TextArea, LaunchButton, ButtonContainer, InviteContainerV2, ScrollableContainerV2, LocationContainer}